$input-bg-color: #fff;
$input-text-color: black;
$button-bg-color: #7f8ff4;
$button-text-color: #fff;

a {
  color: inherit;

  &:hover {
    color: $button-bg-color;
  }
}

//** helper
.input-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.uppercase {
  text-transform: uppercase;
}

//** button
.btn {
  display: inline-block;
  background: transparent;
  color: inherit;
  font: inherit;
  border: 0;
  outline: 0;
  padding: 0;
  transition: all 200ms ease-in;
  cursor: pointer;

  &--primary {
    background: $button-bg-color;
    color: $button-text-color;
    box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.1);
    border-radius: 2px;
    padding: 12px 36px;

    &:hover {
      background: darken($button-bg-color, 4%);
    }

    &:active {
      background: $button-bg-color;
      box-shadow: inset 0 0 10px 2px rgba(0, 0, 0, 0.2);
    }
  }

  &--inside {
    margin-left: -96px;
  }
}

//** form
.form {
  &__field {
    width: 460px;
    margin-bottom: 30px;
    background: #fff;
    color: $input-text-color;
    font: inherit;
    box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.1);
    border: 0;
    outline: 0;
    padding: 22px 100px 22px 18px;
  }
}

@media screen and (max-width: 850px) {
  .form__field {
    max-width: 380px;
    padding-right: 100px;
  }
}

@media screen and (max-width: 600px) {
  .form__field {
    max-width: 280px;
    padding-right: 100px;
  }
}

@media screen and (max-width: 450px) {
  .form__field {
    max-width: 230px;
    padding-right: 100px;
  }
}