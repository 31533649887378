.social-icons-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 400px;
    margin-bottom: 30px;
}

@media screen and (max-width: 850px) {
    .social-icons-container {
        width: 300px;
    }
  }

@media screen and (max-width: 600px) {
    .social-icons-container {
        width: 300px;
    }
  }

@media screen and (max-width: 450px) {
    .social-icons-container {
        width: 250px;
    }
  }