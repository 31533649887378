@import url("https://fonts.googleapis.com/css2?family=Comfortaa:wght@600&display=swap");

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: "Comfortaa", cursive;
  height: 100vh;
}

.container::before {
  content: "";
  background-image: url(../assets/homebackground.jpg);
  background-size: contain;
  position: fixed;
  opacity: 0.7;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -1;
}

.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 350px;
  height: 250px;
  // border: 1px red solid;
}

.title {
  margin-bottom: 30px;
  text-decoration: underline;
}

.description {
  margin: 0 100px 30px 100px;
}

.cubes {
  position: fixed;
  width: 100%;
  height: 100%;
  overflow: hidden;
  pointer-events: none;
}

.cube {
  position: absolute;
  display: block;
  width: 20px;
  height: 20px;
  background: rgba(0, 0, 0, 0.2);
  bottom: -160px;
  animation: animate 25s linear infinite;
}

.cubes div:nth-child(1) {
  left: 25%;
  width: 80px;
  height: 80px;
  animation-delay: 3s;
  animation-duration: 20s;
}

.cubes div:nth-child(2) {
  left: 10%;
  width: 20px;
  height: 20px;
  animation-delay: 2s;
  animation-duration: 12s;
}

.cubes div:nth-child(3) {
  left: 70%;
  width: 20px;
  height: 20px;
  animation-delay: 4s;
}

.cubes div:nth-child(4) {
  left: 40%;
  width: 60px;
  height: 60px;
  animation-delay: 3s;
  animation-duration: 18s;
}

.cubes div:nth-child(5) {
  left: 65%;
  width: 20px;
  height: 20px;
  animation-delay: 0s;
  animation-duration: 18s;
}
.cubes div:nth-child(6) {
  left: 70%;
  width: 150px;
  height: 150px;
  animation-delay: 3s;
  animation-duration: 30s;
  /* background: teal; */
}
.cubes div:nth-child(7) {
  left: 35%;
  width: 150px;
  height: 150px;
  animation-delay: 7s;

  /* background: teal; */
}
.cubes div:nth-child(8) {
  left: 50%;
  width: 25px;
  height: 25px;
  animation-delay: 15s;
  animation-duration: 45s;
  /* background: teal; */
}
.cubes div:nth-child(9) {
  left: 20%;
  width: 15px;
  height: 15px;
  animation-delay: 2s;
  animation-duration: 35s;
  /* background: teal; */
}
.cubes div:nth-child(10) {
  left: 85%;
  width: 150px;
  height: 150px;
  animation-delay: 0s;
  animation-duration: 60s;
  /* background: teal; */
}

@keyframes animate {
  0% {
    opacity: 1;
    border-radius: 0;
    transform: translateY(0) rotate(0deg);
  }
  100% {
    opacity: 0;
    border-radius: 50%;
    transform: translateY(-1000px) rotate(720deg);
  }
}

@media screen and (max-width: 850px) {
  .logo {
    width: 320px;
    height: 220px;
  }
  .title {
    font-size: 1.5rem;
  }
  .description {
    font-size: 1.4rem;
    margin: 0 50px 30px 50px;
  }
}

@media screen and (max-width: 600px) {
  .logo {
    width: 280px;
    height: 180px;
  }
  .title {
    font-size: 1.3rem;
  }
  .description {
    font-size: 1.3rem;
    margin: 0 20px 30px 20px;
  }
}

@media screen and (max-width: 450px) {
  .logo {
    width: 280px;
    height: 180px;
  }
  .title {
    font-size: 1.3rem;
  }
  .description {
    font-size: 1.2rem;
    margin: 0 10px 30px 10px;
  }
}
